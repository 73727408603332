<template>
  <div>
    <div class="supply-chain-content">
      <div class="wave">
        <svg
            viewBox="0 0 1440 320"
            xmlns="http://www.w3.org/2000/svg">
          <path
              fill="#c9e2ff"
              fill-opacity="1"
              d="m 0.0038651,134.63616 80.0000009,-26.7 C 160.00385,81.636162 320.00385,27.636162 480.00385,33.336162 c 160,5.3 320,69.299998 480,79.999998 160.00005,10.3 320.00005,-31.699998 400.00005,-53.399998 l 80,-21.3 V -4.143e-5 h -80 -400.00005 -480 H 80.003866 0.0038651 Z"
              id="path2"
              style="fill-opacity:1;fill:#c9e2ff"/>
        </svg>
      </div>
      <div class="container is-max-widescreen">

        <div class="supply-chain-text">
          <div class="container is-max-widescreen">
            <h2 class="content-title">Integrated Supply Chain</h2>
            <p class="mb-4">{{ $t('supply.desc') }}</p>
          </div>
        </div>

        <div class="columns is-tablet">
          <div class="column is-4-tablet">
            <div class="card">
              <div class="supply-chain-img">
                <img src="/assets/img/supply/supply-1.png" :alt="$t('supply.harvest_team')">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="#93C0F5" fill-opacity="1"
                        d="M0,224L120,208C240,192,480,160,720,160C960,160,1200,192,1320,208L1440,224L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                </svg>
              </div>
              <div class="card-content">
                <p class="supply-chain-title">{{ $t('supply.harvest_team') }}</p>
              </div>
            </div>
          </div>
          <div class="column is-4-tablet">
            <div class="card">
              <div class="supply-chain-img">
                <img src="/assets/img/supply/supply-2.png" :alt="$t('supply.logistic')">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="#68A7F1" fill-opacity="1"
                        d="M0,224L120,208C240,192,480,160,720,160C960,160,1200,192,1320,208L1440,224L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                </svg>
              </div>
              <div class="card-content">
                <p class="supply-chain-title">{{ $t('supply.logistic') }}</p>
              </div>
            </div>
          </div>
          <div class="column is-4-tablet">
            <div class="card">
              <div class="supply-chain-img">
                <img src="/assets/img/supply/supply-3.png" :alt="$t('supply.payment')">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path fill="#3C8DED" fill-opacity="1"
                        d="M0,224L120,208C240,192,480,160,720,160C960,160,1200,192,1320,208L1440,224L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path>
                </svg>
              </div>
              <div class="card-content">
                <p class="supply-chain-title">{{ $t('supply.payment') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupplyChain",
}
</script>

<style lang="scss" scoped>
.supply-chain-text {
  margin-bottom: 4rem;
  color: #363636;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}

.supply-chain-content {
  background-color: #c9e2ff;
  color: #363636;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 4rem;
  //margin-top: 50px;
  margin-bottom: 8rem;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }

  .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    transform: translateY(100%);
    z-index: 1;
    @media (max-width: 768px) {
      bottom: 1px;
    }
  }

  .card {
    height: 100%;
  }

  .supply-chain-title {
    color: #128FE3;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: .5rem;
    text-align: center;
  }


  .supply-chain-img {
    position: relative;
    background-color: #93C0F5;
    margin-bottom: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;
    border-radius: 24px 24px 0 0;
    align-items: center;
    height: 120px;

    > img {
      height: 80px;
    }

    > svg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(50%);
      z-index: -1;
    }
  }

  .column {
    &:nth-child(2) {
      .supply-chain-img {
        background-color: #68A7F1;
      }
    }

    &:nth-child(3) {
      .supply-chain-img {
        background-color: #3C8DED;
      }
    }
  }

  .card-content {
    padding: .5rem;
  }
}
</style>
